import logo from './logo.svg';
import './App.css';
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { useState, useEffect } from 'react';

import Timer from './components/countdown/Timer';

function App() {
  return (
    <div className='annvicabs'>
      <Parallax pages={2} style={{ top: '0', left: '0' }} class="animation">
      <ParallaxLayer offset={0} speed={0.25}>
          <div class="animation_layer parallax" id="line"></div>
          {/* <image src={'./Images/Assets/Line.png'} style={{width: '100%',}} /> */}
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.3}>
          <div class="animation_layer parallax" id="logobg"></div>
          {/* <image src={'./Images/Assets/Logobg.png'} style={{width: '100%'}} /> */}
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.3}>
          <div class="animation_layer parallax" id="logo"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.3}>
          <div class="animation_layer parallax" id="blacklogowithtext"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.35}>
          <div class="animation_layer parallax" id="locationtophalf"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.42}>
          <div class="animation_layer parallax" id="bottomleftdesign"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.45}>
          <div class="animation_layer parallax" id="location1"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.40}>
          <div class="animation_layer parallax" id="mobilebg"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.35}>
          <div class="animation_layer parallax" id="mobile"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.52}>
          <div class="animation_layer parallax" id="locationsm"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.45}>
          <div class="animation_layer parallax" id="locationbottomleft"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.4}>
          <div class="animation_layer parallax" id="text1"></div>
          <Timer />
        </ParallaxLayer>
        {/* <ParallaxLayer offset={0} speed={0.4}>
          <div class="animation_layer parallax">

          </div>
        </ParallaxLayer> */}
        <ParallaxLayer offset={0} speed={0.25}>
          <div class="animation_layer parallax" id="leftyellowbg"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.42}>
          <div class="animation_layer parallax" id="instagram"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.42}>
          <div class="animation_layer parallax" id="facebook"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.42}>
          <div class="animation_layer parallax" id="twitter"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={0.42}>
          <div class="animation_layer parallax" id="whatsaapp"></div>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={0.42}>
          {/* <div class="animation_layer parallax" id="whatsaapp"></div> */}
        </ParallaxLayer>
      </Parallax>

    </div>
  );
}

export default App;
